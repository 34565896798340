import React from 'react';

import { ProductListItems } from './ProductListItems';
import { useProductListGtm } from './hooks/useProductListGtm';
import { ProductListItem } from './utils/productListItem';

type Props = {
    products: ProductListItem[];
    loading: boolean;
    listName?: string;
    listId?: string;
    openProductInNewTab?: boolean;
};

export function ProductList(props: Props) {
    /**
     * GTM
     */
    const { gtmSelectItem } = useProductListGtm({
        products: props.products,
        listName: props.listName,
        listId: props.listId,
    });

    return (
        <ProductListItems
            products={props.products}
            gtmSelectItem={gtmSelectItem}
            loading={props.loading}
            openProductInNewTab={props.openProductInNewTab}
        />
    );
}
