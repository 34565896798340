import React from 'react';

import { SkeletonItem } from 'src/components/Helpers/SkeletonItem';

import classes from './SkeletonItem.scss';

type Props = {
    qty?: number;
};

export const SkeletonItemList: React.FC<Props> = ({ qty }) => {
    return (
        <ul className={classes.container}>
            {[...new Array(qty)].map((_, index) => {
                return (
                    <li className={classes.item} key={index}>
                        <SkeletonItem />
                    </li>
                );
            })}
        </ul>
    );
};

SkeletonItemList.defaultProps = {
    qty: 1,
};
