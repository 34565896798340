import React from 'react';

import { SkeletonBlock } from 'src/components/Helpers/SkeletonBlock';

import { SkeletonLine } from '../Helpers/SkeletonLine';

import classes from './SkeletonItem.scss';

export function SkeletonItem() {
    return (
        <div className={classes.content}>
            <SkeletonBlock className={classes.image} />
            <div className={classes.info}>
                <SkeletonLine />
                <SkeletonLine width={5} />
                <SkeletonLine />
                <div className={classes.footer}>
                    <SkeletonLine width={10} />
                </div>
            </div>
        </div>
    );
}
