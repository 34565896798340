import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';

import { Wrapper, Container } from '../Layout';
import { Offline } from '../Offline/Offline';
import { StoreState } from '../../types/global-types';

import { ErrorComponent } from './ErrorComponent';

type ErrorViewProps = {
    message?: string;
};

export function ErrorOrOfflineConnected(props: ErrorViewProps) {
    const state = useSelector((state: StoreState) => {
        return {
            online: state.runtime.online,
        };
    }, shallowEqual);
    useSetResolved();
    const inner = (() => {
        if (!state.online) {
            return <Offline />;
        }

        if (props.message) {
            return <ErrorComponent message={props.message} />;
        }

        return <ErrorComponent message="500 Internal Server Error" />;
    })();

    return (
        <Wrapper>
            <Container>{inner}</Container>
        </Wrapper>
    );
}
