import { imagePath } from 'src/util/formatUrl';
import { ProductPrice } from 'src/types/global-types';
import { productPrice } from 'src/components/ProductDetail/utils/product-detail.types';
import { productName } from 'src/util/productName';
import { SingleLabel } from 'src/components/ProductLabels/ProductLabel';
import { cleanLabelData } from 'src/components/ProductLabels/utils/ProductLabelData';
import { ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';

import {
    categoryProducts_categoryProducts_items,
    categoryProducts_categoryProducts_items_labels,
} from '../../../queries/__generated__/categoryProducts';

export interface ProductListItem {
    id: number | string;
    name: string;
    url: string;
    price: ProductPrice;
    small_image: {
        label: string;
        url: string;
    };
    click_collect: boolean;
    delivery: boolean;
    labels: SingleLabel[] | ProductHitLabelData[];
    energy_rating?: string | null;
    product_fiche_image?: string | null;
    energy_label_image?: string | null;
}

export function productListItemsFromQuery(
    items: (categoryProducts_categoryProducts_items | null)[],
    options: { url_suffix: string },
): ProductListItem[] {
    return items.filter(Boolean).map((item) => {
        if (!item) throw new Error("trying to process a list item that doesn't exist");

        return {
            id: item.id!,
            name: productName(item.name || 'unknown'),
            url: '/' + item.url_key! + options.url_suffix,
            small_image: {
                label: item.small_image?.label || 'unknown',
                url: imagePath(item.small_image?.url),
            },
            price: productPrice(
                item.price_range.minimum_price?.regular_price?.value ?? 0,
                item.special_price,
                item.woodies,
            ),
            click_collect: item.click_and_collected === 1,
            delivery: item.home_delivery === 1 || item.is_available_hd_delivery_centre === 1,
            labels: cleanLabelData<categoryProducts_categoryProducts_items_labels>(item.labels),
            energy_rating: item.woodies?.energy_rating ?? undefined,
            product_fiche_image: item.woodies?.product_fiche_image ?? undefined,
            energy_label_image: item.woodies?.energy_label_image ?? undefined,
        };
    });
}
