import React from 'react';

import { ProductItem } from 'src/components/CategoryListingPage/ProductListItem';

import classes from './ProductList.scss';
import { ProductListItem } from './utils/productListItem';

export type Props = {
    products: ProductListItem[];
    loading: boolean;
    gtmSelectItem(index: number): void;
    openProductInNewTab?: boolean;
};

export function ProductListItems(props: Props) {
    const { loading, gtmSelectItem, products } = props;

    return (
        <ul className={classes.list}>
            {products.map((product, index) => {
                return (
                    <li
                        key={product.id}
                        className={classes.item}
                        data-test-id="ProductItem"
                        data-item-loading={String(loading)}
                    >
                        <ProductItem
                            product={product}
                            gtmSelectItem={gtmSelectItem}
                            index={index}
                            openLinkInNewTab={props.openProductInNewTab}
                        />
                    </li>
                );
            })}
        </ul>
    );
}
